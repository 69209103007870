import { Injectable } from '@angular/core';

declare const Telegram: any;

interface ITGUser {
    first_name: string;
    last_name: string;
    username: string;
    photo_url: string;
    id: number;
}

interface ITGChat {
    id: string;
    username: string;
    photo_url: string;
}

@Injectable({
    providedIn: 'root'
})
export class TelegramService {
    tg: any;

    tgUser: ITGUser = {} as ITGUser;
    tgChat: ITGChat = {} as ITGChat;

    constructor() {}

    init() {
        if (Telegram) {
            Telegram.WebApp.ready();
            this.tg = Telegram.WebApp;
            this.tg.ready();
            this.tg.expand();
            this.tg.disableVerticalSwipes();

            const initDataUnsafe = this.tg.initDataUnsafe;

            this.tgUser = initDataUnsafe.user;
            this.tgChat = initDataUnsafe.chat;
        }
    }
}
