import { Model } from '../abstract/model.abstract';
import { UserProfileModel } from './user-profile.model';
import { UserAccountModel } from './user-account.model';

export class UserModel extends Model {
    id: number;
    roleId: number;
    profile: UserProfileModel;
    account: UserAccountModel;

    override onInit() {
        super.onInit();
        this.profile = UserProfileModel.fromJson(this.profile);
        this.account = UserAccountModel.fromJson(this.account);
    }
}
