import { Model } from '../abstract/model.abstract';
import moment from 'moment';
export class UserAccountModel extends Model {
    coins: number;
    tokens: number;
    energy: number;
    level: number;
    levelExp: number;
    pveLevel: number;
    pvpRank: number;
    activePveLevel: number;
    maxEnergy: number;
    energySpentAt: string;

    get nextLvlPercent() {
        const percent = (this.levelExp / 100) * 100;
        return percent > 100 ? 100 : percent;
    }

    get currentEnergy() {
        const energy = moment().diff(moment(this.energySpentAt), 'hours');

        if (energy) {
            return this.energy + Math.floor(energy) > this.maxEnergy
                ? this.maxEnergy
                : this.energy + Math.floor(energy);
        }

        return this.energy;
    }
}
