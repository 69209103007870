import { Model } from '../abstract/model.abstract';

export class UserProfileModel extends Model {
    id: number;
    firstName: string;
    lastName: string;
    nickname: string;
    avatar: string;
    frame: string;
    createdAt: string;
    updatedAt: string;

    override onInit() {
        this.avatar = '1.webp';
        this.frame = '1.gif';
    }
}
